@mixin theme($theme) {
  // stylelint-disable-next-line selector-max-universal
  * {
    box-sizing: border-box;
  }

  html,
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
  }

  .hidden {
    visibility: hidden;
    position: absolute;
    height: 0;
    width: 0;
  }

  [hidden] {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }
}
