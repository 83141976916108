// stylelint-disable selector-max-specificity,selector-max-id

@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, "foreground");

  #dataset-detail-tabs {
    td {
      a,
      a:visited {
        color: mat.get-color-from-palette($foreground, "text");
      }

      a:hover {
        text-shadow: 1px 0 0 mat.get-color-from-palette($foreground, "text");
      }
    }
  }
}
