@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  a {
    cursor: pointer;
    text-decoration: none;
  }

  a,
  a:visited {
    color: mat.get-color-from-palette($primary, 500);
  }
}
