@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  app-dataset-summary {
    .header-obsolete {
      color: lightpink;
    }
  }
}
