@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent: map.get($color-config, "accent");

  app-landing-page-search-section {
    --section-background-image: url("/assets/images/prunes.jpg");

    app-landing-page-section-content {
      background-color: mat.get-color-from-palette($accent, 500);
    }

    header,
    .suggestions {
      color: mat.get-contrast-color-from-palette($accent, 500);
    }
  }
}
