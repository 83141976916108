@mixin theme($theme) {
  // TODO once migrated to Angular Material 16, add [panelWidth]="null" to mat-select and remove this
  .select-panel-width-fix {
    // https://github.com/angular/components/issues/26000#issuecomment-1582527252
    // https://v16.material.angular.io/components/select/api
    &.mat-mdc-select-panel {
      min-width: fit-content;
    }

    .mat-mdc-option .mdc-list-item__primary-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 650px;
    }
  }
}
