@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, "foreground");
  $background: map.get($color-config, "background");
  $primary: map.get($color-config, "primary");
  $accent: map.get($color-config, "accent");

  app-contribution-value {
    .small-value {
      color: mat.get-color-from-palette($foreground, "quantis-grey-2");
    }

    .bar {
      background-color: mat.get-color-from-palette($accent, 100);
    }

    .negative-value {
      color: mat.get-color-from-palette($primary, 900);
    }
  }

  app-exchange-table {
    .contribution {
      background-color: mat.get-color-from-palette(
        $background,
        "quantis-grey-2"
      );
    }
  }
}
