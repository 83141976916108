@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  ul.plus-list {
    margin: 0;
    padding: 0;

    li {
      list-style-type: "+ ";

      &::marker {
        font-size: 20px;
        color: mat.get-color-from-palette($primary, 500);
      }
    }
  }
}
