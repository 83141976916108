@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  $accent: map.get($color-config, "accent");
  $warn: map.get($color-config, "warn");
  $foreground: map.get($color-config, "foreground");

  .primary-color {
    color: mat.get-color-from-palette($primary, 500);
  }

  .accent-color {
    color: mat.get-color-from-palette($accent, 500);
  }

  .warn-color {
    color: mat.get-color-from-palette($warn, 500);
  }

  .secondary-text-color {
    color: mat.get-color-from-palette($foreground, "secondary-text");
  }

  .disabled-text-color {
    color: mat.get-color-from-palette($foreground, "disabled-text");
  }
}
