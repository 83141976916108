@use "@angular/material" as mat;
@use "sass:map" as map;

@mixin theme($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map.get($config, background);

  .get-custom {
    --main-padding-top: 0;
    --main-padding-bottom: 0;

    section {
      background-color: mat.get-color-from-palette($background, "base");
    }
  }
}
