@use "sass:color";
@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent: map.get($color-config, "accent");

  .landing-page {
    font-size: 16px;

    --mdc-typography-button-font-size: 16px;
    --main-padding-top: 0;
    --main-padding-bottom: 0;

    app-footer {
      --logo-color: #{mat.get-contrast-color-from-palette($accent, 500)};

      background-color: mat.get-color-from-palette($accent, 500);
    }
  }
}
