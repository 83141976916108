@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, "background");

  app-landing-page-about-section {
    --section-background-color: #{mat.get-color-from-palette(
        $background,
        "cream"
      )};
  }
}
