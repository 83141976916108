@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  $accent: map.get($color-config, "accent");
  $background: map.get($color-config, "background");

  app-landing-page-model-section {
    --section-background-color: #{mat.get-color-from-palette(
        $background,
        "quantis-grey-4"
      )};

    .main {
      background-color: mat.get-color-from-palette($background, "base");
    }

    .description {
      background-color: mat.get-color-from-palette($background, "cream");
    }

    .mat-headline-4 {
      color: mat.get-color-from-palette($accent, 500);
    }

    .mat-icon {
      color: mat.get-color-from-palette($accent, 500);
    }
  }
}
