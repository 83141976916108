@use "quantis";
@use "@angular/material" as mat;
@use "mat-icon-button-sizes/style.css";
@use "vendor/ngx-mat-select-search";
@use "global/typography";
@use "global/color";
@use "global/layout";
@use "global/link";
@use "global/header";
@use "global/table";
@use "global/chip";
@use "global/list";
@use "global/select";
@use "global/dialog";
@use "global/tooltip";
@use "../app/components/toolbar/toolbar.component-theme" as toolbar;
@use "../app/components/footer/footer.component-theme" as footer;
@use "../app/modules/landing-page/pages/get-custom/get-custom.component-theme"
  as get-custom;
@use "../app/modules/landing-page/pages/landing-page/landing-page.component-theme"
  as landing-page;
@use "../app/modules/landing-page/components/landing-page-search-section/landing-page-search-section.component-theme"
  as landing-page-search-section;
@use "../app/modules/landing-page/components/landing-page-model-section/landing-page-model-section.component-theme"
  as landing-page-model-section;
@use "../app/modules/landing-page/components/landing-page-about-section/landing-page-about-section.component-theme"
  as landing-page-about-section;
@use "../app/modules/ui/modules/filters-group/components/chips-group/chips-group.component-theme"
  as chips-group;
@use "../app/modules/ui/modules/filters-group/components/filters-group/filters-group.component-theme"
  as filters-group;
@use "../app/modules/dataset/components/dataset-summary/dataset-summary.component-theme"
  as datasets-summary;
@use "../app/modules/dataset/components/datasets-search-form/datasets-search-form.component-theme"
  as datasets-search-form;
@use "../app/modules/dataset/components/datasets-search-results/datasets-search-results.component-theme"
  as datasets-search-results;
@use "../app/modules/dataset/modules/dataset-detail/pages/dataset-detail/dataset-detail.component-theme"
  as dataset-detail;
@use "../app/modules/dataset/modules/impact-factor/components/impact-factor-sample-list/impact-factor-sample-list.component-theme"
  as impact-factor-sample-list;
@use "../app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-table.component-theme.scss"
  as exchange-table;
@use "../app/modules/dataset/modules/embedded-product/components/embedded-product-tab/embedded-product-tab.component-theme.scss"
  as embedded-product-tab;
@use "../app/modules/dataset/modules/dataset-detail/components/impact-factors-tab/impact-factors-table/impact-factors-table.component-theme.scss"
  as impact-factors-table;
@use "../app/modules/dataset/modules/exchange/components/exchange-tab/exchange-table/exchange-details-subtotal/exchange-details-subtotal.component-theme.scss"
  as exchange-subtotal;
@use "../app/modules/characterization-factor/pages/characterization-factors-search/characterization-factors-search.component-theme"
  as characterization-factors;
@use "../app/modules/basket/components/basket-import-dialog/basket-import-dialog.component-theme.scss"
  as basket-import;

@include mat.core();
@include mat.typography-hierarchy(quantis.$typography);
@include mat.all-component-themes(quantis.$theme);
@include ngx-mat-select-search.theme(quantis.$theme);
@include typography.theme(quantis.$theme);
@include color.theme(quantis.$theme);
@include layout.theme(quantis.$theme);
@include link.theme(quantis.$theme);
@include header.theme(quantis.$theme);
@include table.theme(quantis.$theme);
@include chip.theme(quantis.$theme);
@include list.theme(quantis.$theme);
@include select.theme(quantis.$theme);
@include dialog.theme(quantis.$theme);
@include tooltip.theme(quantis.$theme);
@include toolbar.theme(quantis.$theme);
@include footer.theme(quantis.$theme);
@include get-custom.theme(quantis.$theme);
@include landing-page.theme(quantis.$theme);
@include landing-page-search-section.theme(quantis.$theme);
@include landing-page-model-section.theme(quantis.$theme);
@include landing-page-about-section.theme(quantis.$theme);
@include chips-group.theme(quantis.$theme);
@include filters-group.theme(quantis.$theme);
@include datasets-summary.theme(quantis.$theme);
@include datasets-search-form.theme(quantis.$theme);
@include datasets-search-results.theme(quantis.$theme);
@include dataset-detail.theme(quantis.$theme);
@include impact-factor-sample-list.theme(quantis.$theme);
@include exchange-table.theme(quantis.$theme);
@include embedded-product-tab.theme(quantis.$theme);
@include impact-factors-table.theme(quantis.$theme);
@include exchange-subtotal.theme(quantis.$theme);
@include characterization-factors.theme(quantis.$theme);
@include basket-import.theme(quantis.$theme);

.dark-theme {
  @include mat.button-color(quantis.$dark-theme);
}
