@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  .mat-mdc-dialog-container {
    --mdc-dialog-subhead-color: #{mat.get-color-from-palette($primary, 500)};
  }
}
