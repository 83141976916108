@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");
  $foreground: map.get($color-config, "foreground");

  .characterization-factors-search {
    form {
      border-bottom: solid 1px
        mat.get-color-from-palette($foreground, "divider");
    }

    app-chips-group::after {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }

    .clear-all .mat-icon {
      color: mat.get-color-from-palette($foreground, "text");
      opacity: 0.4;
    }

    .results-amount,
    .export-warning {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}
