@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, "background");

  .mat-select-search-input {
    --mat-select-panel-background-color: #{mat.get-color-from-palette(
        $background,
        "base"
      )};
  }

  .mat-select-search-clear {
    transform: scale(0.75);
  }
}
