@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, "foreground");

  app-impact-factor-sample-list {
    .impact-co2 {
      color: mat.get-color-from-palette($foreground, "secondary-text");
      border: 1px solid
        mat.get-color-from-palette($foreground, "secondary-text");
    }

    .impact-co2-method {
      color: white;
      background: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}
