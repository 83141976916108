@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $warn: map.get($color-config, "warn");

  app-basket-import-dialog {
    .mat-error a {
      color: mat.get-color-from-palette($warn, 500);
    }
  }
}
