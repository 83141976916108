@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  table:not(.mat-mdc-table) {
    border-spacing: 0;
  }

  .mat-mdc-tab-header {
    padding-bottom: 8px;
  }

  .simple-table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-weight: lighter;
      text-align: left;
      padding-right: 16px;
    }

    td {
      padding-right: 5px;
    }
  }

  .simple-table h2 {
    margin-bottom: 0.5em;
  }

  .simple-table h3 {
    margin-bottom: 0.3em;
  }
}
