@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, "background");
  $accent: map.get($color-config, "accent");

  app-impact-factors-table {
    .total {
      background-color: mat.get-color-from-palette(
        $background,
        "quantis-grey-1"
      );
      font-weight: bolder;
    }

    .subtotal {
      background-color: mat.get-color-from-palette(
        $background,
        "quantis-grey-2"
      );
      font-weight: bold;
    }

    .small-value {
      color: mat.get-color-from-palette($background, "quantis-grey-1");
    }

    .bar {
      background-color: mat.get-color-from-palette($accent, 100);
    }
  }
}
