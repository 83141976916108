@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, "foreground");

  app-datasets-search-results {
    .result .label {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}
