@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  [exchangesubtotal] {
    .subtotal-bar {
      border-top-color: mat.get-color-from-palette(mat.$grey-palette, 800);
    }
  }
}
