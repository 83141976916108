.mat-large-icon-button {
  width: 40px !important;
  height: 40px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center; }
  .mat-large-icon-button > *[role=img] {
    width: 24px;
    height: 24px;
    font-size: 24px; }
    .mat-large-icon-button > *[role=img] svg {
      width: 24px;
      height: 24px; }
  .mat-large-icon-button .mat-mdc-button-touch-target {
    width: 40px !important;
    height: 40px !important; }

.mat-medium-icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center; }
  .mat-medium-icon-button > *[role=img] {
    width: 19px;
    height: 19px;
    font-size: 19px; }
    .mat-medium-icon-button > *[role=img] svg {
      width: 19px;
      height: 19px; }
  .mat-medium-icon-button .mat-mdc-button-touch-target {
    width: 32px !important;
    height: 32px !important; }

.mat-small-icon-button {
  width: 26px !important;
  height: 26px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center; }
  .mat-small-icon-button > *[role=img] {
    width: 16px;
    height: 16px;
    font-size: 16px; }
    .mat-small-icon-button > *[role=img] svg {
      width: 16px;
      height: 16px; }
  .mat-small-icon-button .mat-mdc-button-touch-target {
    width: 26px !important;
    height: 26px !important; }

.mat-tiny-icon-button {
  width: 20px !important;
  height: 20px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center; }
  .mat-tiny-icon-button > *[role=img] {
    width: 12px;
    height: 12px;
    font-size: 12px; }
    .mat-tiny-icon-button > *[role=img] svg {
      width: 12px;
      height: 12px; }
  .mat-tiny-icon-button .mat-mdc-button-touch-target {
    width: 20px !important;
    height: 20px !important; }
