@use "sass:color";
@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, "foreground");
  $background: map.get($color-config, "background");
  $primary: map.get($color-config, "primary");
  $stripe1: #eaeaea;
  $stripe2: #dcdbdb;

  app-toolbar {
    .mat-mdc-mini-fab.basic {
      --mdc-fab-icon-color: #{mat.get-color-from-palette($foreground, "text")};
      --mat-mdc-fab-color: #{mat.get-color-from-palette($foreground, "text")};
      --mdc-fab-container-color: #{mat.get-color-from-palette(
          $background,
          "base"
        )};
    }

    .banner {
      color: mat.get-color-from-palette($primary, 500);
      background: repeating-linear-gradient(
        -45deg,
        $stripe1,
        $stripe1 25px,
        $stripe2 0,
        $stripe2 50px
      );
    }
  }
}
