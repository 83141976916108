@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  h1,
  h2,
  h3 {
    color: mat.get-color-from-palette($primary, default);
  }

  h2 {
    padding-top: 8px;
  }

  .mat-headline-4,
  .mat-typography .mat-headline-4 {
    margin: 0 0 16px;
  }
}
