@use "sass:color";
@use "sass:map";
@use "node_modules/@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, "primary");

  app-footer {
    .quantis-logo {
      color: var(--logo-color, #{mat.get-color-from-palette($primary, 500)});
    }
  }
}
